import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { conf } from '../../domain/services/configService';
import { dateService } from '../../domain/services/dateService';
import { theme } from '../../domain/theme';

const sponsorAdditionalText = css`
  ${theme.font.family('regularWeb')};
  -webkit-font-smoothing: antialiased;
  letter-spacing: normal;
  border: 1px solid #c9c9c9;
  color: #999;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  margin: 32px auto;
  padding: 16px 20px;
  overflow: hidden;
  text-align: center;

  @media (min-width: 768px) {
    & {
      width: calc(100% - 370px);
    }
  }
`;

export const SponsoredAdditionalText = ({ content }) => {
  let sponsoredAdditionalText = null;
  if (
    content.branded &&
    (content.sponsoredText || content.sponsoredTextImage)
  ) {
    sponsoredAdditionalText = conf.sponsoredDefaultAdditionalText;
    if (
      conf.sponsoredTravellerAdditionalText &&
      conf.siteCountryCode === 'uk' &&
      content.primaryTaxonomy?.entity?.tid === 247576
    ) {
      sponsoredAdditionalText = conf.sponsoredTravellerAdditionalText;
    }

    if (
      content.sponsoredAdditionalText &&
      content.sponsoredAdditionalText.value
    ) {
      sponsoredAdditionalText = content.sponsoredAdditionalText.value;
    }
  }

  const isWithinDateRange = dateService.isWithinDateRange(
    Math.floor(new Date().getTime() / 1000),
    content.sponsoredStartDate?.timestamp,
    content.sponsoredEndDate?.timestamp
  );

  return sponsoredAdditionalText && isWithinDateRange ? (
    <div
      className="sponsor_additional_text"
      css={sponsorAdditionalText}
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{
        __html: `${sponsoredAdditionalText}`
      }}
    />
  ) : (
    <div />
  );
};

SponsoredAdditionalText.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
